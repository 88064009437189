import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  BannerPage,
  Colors,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
  TitleSection,
} from "../components";
import Img from "gatsby-image";
import { Row, Col } from "antd";
import Slider from "react-slick";

const GrandStudioPage = () => {
  const data = useStaticQuery(graphql`
    query {
      Studio1: file(relativePath: { eq: "studio/1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Studio2: file(relativePath: { eq: "studio/2.png" }) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Studio3: file(relativePath: { eq: "studio/3.png" }) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Studio5: file(relativePath: { eq: "studio/5.png" }) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Studio6: file(relativePath: { eq: "studio/6.png" }) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Studio4: file(relativePath: { eq: "studio/4.png" }) {
        childImageSharp {
          fluid(maxHeight: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title="Grand Studio - Grandeur Homes’ design and inspirational showroom"
        description={
          "Grand Studio is Grandeur Homes’ design and inspirational showroom a space that makes your home truly come to life."
        }
      />
      <BannerPage
        title={"Grand Studio"}
        subTitle={"Grandeur Homes’ design and inspirational showroom"}
      />
      <ContainerContent>
        <ContainerContentFixed>
          <Row gutter={[36, 18]}>
            <Col md={12} xs={24}>
              <TitleSection>Grand Studio</TitleSection>
              <div style={{ fontSize: "1.3rem", paddingRight: "36px" }}>
                <p>
                  <b>
                    Grand Studio is Grandeur Homes’ design and inspirational
                    showroom a space that makes your home truly come to life.
                  </b>
                </p>
                <p>
                  At the Grand Studio, you get to see, touch and feel a broad
                  range of product to suit your needs.
                </p>
                <p>
                  Choosing your fixtures, colours & finishes is such an exciting
                  part of the build process where you really feel your homes
                  vision become a reality.
                </p>
                <p>
                  Our experienced designers will guide you step-by-step through
                  your selection, making your experience fun and relaxing,
                  bringing your ideas together and creating a home that is
                  uniquely yours.
                </p>
              </div>
            </Col>
            <Col md={12} xs={24}>
              <Img fluid={data["Studio1"].childImageSharp.fluid} />
            </Col>
          </Row>
        </ContainerContentFixed>
      </ContainerContent>
      <section>
        <ContainerContent
          style={{ backgroundColor: Colors.LightGray, textAlign: "center" }}
        >
          <Slider
            infinite={true}
            autoplay={true}
            dots={true}
            arrows={false}
            pauseOnHover={true}
            // variableWidth={true}
            slidesToShow={3}
            // slidesToScroll={1}
            responsive={[
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {" "}
            <Img fluid={data["Studio5"].childImageSharp.fluid} />
            <Img fluid={data["Studio2"].childImageSharp.fluid} />
            <Img fluid={data["Studio6"].childImageSharp.fluid} />
            <Img fluid={data["Studio4"].childImageSharp.fluid} />
            <Img fluid={data["Studio3"].childImageSharp.fluid} />
          </Slider>
        </ContainerContent>
      </section>
      <ContainerCta
        title={" We cannot wait to see you at our Grand Studio!"}
        secondaryText={"Get Started"}
        secondaryLink={InternalLinkTypes.GetStarted}
      />
    </Layout>
  );
};
export default GrandStudioPage;
